import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggedInGuard } from './shared/guards/loggedIn/loggedIn.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu/event',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then(mod => mod.SignupModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./modules/menu/menu.module').then(mod => mod.MenuModule),
    canActivate: [
      LoggedInGuard
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./modules/editProfile/editProfile.module').then(mod => mod.EditProfileModule)
  },
  {
    path: 'legal-info',
    loadChildren: () => import('./modules/legalInfo/legalInfo.module').then(mod => mod.LegalInfoModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./modules/changePassword/changePassword.module').then(mod => mod.ChangePasswordModule)
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
