import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpeakersService } from '../api/speakers/speakers.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  url = localStorage.getItem('API_URL');

  selectedEvent?: any = {};

  constructor(
    private http: HttpClient
  ) { }

  setEvent(event) {
    this.selectedEvent = event;
  }

  getEvent() {
    return this.selectedEvent;
  }

  /**
   * Info of the event
   */
  public showInfoEvent(): any {
    return this.http.get(this.url + '/congress/event',
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      })
      .toPromise().then((result) => this.showInfoEventSuccess(result), (result) => this.showInfoEventError(result));
  }

  /**
   * Succes on showInfoEvent
   *
   * @param result
   */
  showInfoEventSuccess(result) {
    if (!result.error) {
      if (result.events.length !== 0) {
        this.selectedEvent = result.events[0];
        this.saveEventId(this.selectedEvent.event_id);
        return result.events[0];
      }
    }
    this.showInfoEventError(result);
  }

  /**
   * Error on showInfoEvent
   *
   * @param result
   */
  showInfoEventError(result) {
    return null;
  }

  saveEventId(eventId) {
    localStorage.setItem('eventId', eventId);
  }

  public setSchedule(schedule: any): any {
    schedule.streaming = JSON.stringify(schedule.streaming);
    return this.http.post(this.url + '/congress/schedule',
      JSON.stringify(schedule),
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise()
      .then((result) => result);
  }

  public deleteSchedule(scheduleId: any): any {
    return this.http.delete(this.url + '/congress/schedule/' + scheduleId,
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise()
      .then((result) => result);
  }

  public updateSchedule(schedule: any, scheduleId: any): any {
    schedule.streaming = JSON.stringify(schedule.streaming);
    return this.http.post(this.url + '/congress/schedule/' + scheduleId,
      JSON.stringify(schedule),
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise()
      .then((result) => result);
  }
}
