import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'woutick-logo',
  templateUrl: './woutickLogo.component.html',
  styleUrls: ['./woutickLogo.component.scss'],
})
export class WoutickLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
