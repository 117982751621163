import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnnotationsService {

  url = localStorage.getItem('API_URL');

  constructor(
    private http: HttpClient,
  ) { }

  public setAnnotation(userId, annotation): any {
    const json = `{
      "userAnnotation_id": "` + userId + `",
      "annotation": "` + annotation + `"
    }`;
    return this.http.post(this.url + '/congress/setannotation',
    json,
    {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result);
  }

  public updateAnnotation(annotationId, annotation): any {
    const json = `{
      "annotation_id": "` + annotationId + `",
      "annotation": "` + annotation + `"
    }`;
    return this.http.post(this.url + '/congress/updateannotation',
    json,
    {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result);
  }

  public getAnnotation(userId): any {
    return this.http.get(this.url + '/congress/showannotationforuser/' + userId,
    {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result);
  }

  public deleteAnnotation(annotationId): any {
    return this.http.delete(this.url + '/congress/deleteannotation/' + annotationId,
    {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result);
  }
}
