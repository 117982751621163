import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  modules = {
    myInfo: {
      active: true,
      guest: false
    },
    notifications: {
      active: true,
      guest: false
    },
    chat: {
      active: false,
      guest: false
    },
    agenda: {
      active: true,
      guest: true
    },
    info: {
      active: true,
      guest: true
    },
    streamings: {
      active: false,
      guest: false
    },
    coworking: {
      active: false,
      guest: false
    },
    companies: {
      active: false,
      guest: false
    },
    jobOffers: {
      active: false,
      guest: false
    },
    meetings: {
      active: false,
      guest: false
    },
    meeters: {
      active: false,
      guest: false
    },
    program: {
      active: true,
      guest: true
    },
    participants: {
      active: false,
      guest: false
    },
    foodtrucks: {
      active: false,
      guest: false
    },
    polls: {
      active: false,
      guest: false
    },
    sponsors: {
      active: false,
      guest: false
    },
    gallery: {
      active: false,
      guest: false
    },
    assistants: {
      active: false,
      guest: false
    },
    chatHelp: {
      active: false,
      guest: false
    },
    externalLink: {
      active: true,
      guest: false
    },
    gamification: {
      active: false,
      guest: false
    }
  };

  public getModules() {
    return this.modules;
  }

  getModulesCount() {
    let count = 0;
    for (let key in this.modules) {
      if (key) {
        count++;
      }
    }
    return count;
  }

  getActiveModulesCount() {
    let count = 0;
    for (let key in this.modules) {
      if (this.modules[key].active) {
        count++;
      }
    }
    return count;
  }
}
