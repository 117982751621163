import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ChangePasswordService } from '../../services/api/changePassword/changePassword.service';
import { ChatService } from '../../services/chat/chat.service';

@Component({
  selector: 'form-password',
  templateUrl: './formPassword.component.html',
  styleUrls: ['./formPassword.component.scss'],
})
export class FormPasswordComponent implements OnInit {

  // Class variables
  @Input() isPopover = false;
  public passForm: FormGroup = this.fb.group({
    password: ['', [Validators.required]],
    repPassword: ['', [Validators.required]]
  },
    {
      validators: this.checkPassword
    });

  public showPass = false;
  public showRepPass = false;

  @Output() sendEvent = new EventEmitter<any>();

  constructor(
    private location: Location,
    private fb: FormBuilder,
    public alertController: AlertController,
    public translateService: TranslateService,
    private changePassService: ChangePasswordService,
    public chatService: ChatService
  ) { }

  ngOnInit() { }

  checkPassword(c: AbstractControl) {
    if (c.get('password').value !== c.get('repPassword').value || c.get('password').value.length < 6) {
      return { invalid: true };
    }
  }

  showHidePass() {
    const inputPass = document.getElementById('newpassword');
    if (inputPass['type'] === 'password') {
      inputPass['type'] = 'text';
    } else {
      inputPass['type'] = 'password';
    }
    this.showPass = !this.showPass;
  }

  showHideRepPass() {
    const inputPass = document.getElementById('repPassword');
    if (inputPass['type'] === 'password') {
      inputPass['type'] = 'text';
    } else {
      inputPass['type'] = 'password';
    }
    this.showRepPass = !this.showRepPass;
  }

  /**
   * Checks if the form is valid
   */
  public isFormDisabled(): boolean {
    return !this.passForm.valid;
  }

  /**
   * Submits the login form
   */
  public changePass(): void {
    if (!this.isFormDisabled()) {
      this.changePassService.changePasswordUser(this.passForm.value.password)
        .then((result) => this.changePasswordUserSuccess(result), (result) => this.changePasswordUserError(result));
    }
  }


  /**
   * Success on login user
   *
   * @param result
   */
  private changePasswordUserSuccess(result: any): void {
    if (!result.error) {
      // There is no error
      this.presentAlertSuccess();
    } else {
      this.changePasswordUserError(result);
    }
  }

  /**
   * Error on login user
   *
   * @param result
   */
  private changePasswordUserError(result: any): void {
    console.log('ERROR');
  }

  async presentAlertSuccess() {
    const title = await this.translateService.get('CHANGE_PASS.alert.success.title').toPromise()
      .then((value) => {
        return value;
      });
    const message = await this.translateService.get('CHANGE_PASS.alert.success.message').toPromise()
      .then((value) => {
        return value;
      });
    if (title && message) {
      const alert = await this.alertController.create({
        header: title,
        message,
        buttons: [{
          text: 'Ok',
          handler: () => this.sendEvent.emit()
        }]
      });

      await alert.present();
    }
  }

  goBack() {
    this.sendEvent.emit();
  }
}
