import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageEvent'
})
export class imageEventPipe implements PipeTransform {

  transform(value: string): string {
    let name = '';
    switch (value) {
      case 'logo':
        name = 'cnp.png'; // TODO obtener el nombre del logo
        return 'https://congress-wtk.s3-eu-west-1.amazonaws.com/logos/' + name;
      case 'background':
        name = 'cnp.jpg'; // TODO obtener el nombre del fondo
        return 'url(\"https://congress-wtk.s3-eu-west-1.amazonaws.com/backgrounds/' + name + '\")';
      case 'favicon':
        name = 'cnp.png'; // TODO obtener el nombre del favicon
        return 'https://congress-wtk.s3-eu-west-1.amazonaws.com/favicons/' + name;
    }
  }

}
