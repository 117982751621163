import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'legal-info-tabs',
  templateUrl: './legalInfoTabs.component.html',
  styleUrls: ['./legalInfoTabs.component.scss'],
})
export class LegalInfoTabsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
